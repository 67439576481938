<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
              <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
            <v-col cols="12" class="mt-3">
                <v-card class="pa-md-2 " outlined >
                    <v-col cols="12" xs="12" sm="12" md="12" class="mb-3">
                    <div class="d-flex">
                            <v-card class="rounded-l mr-5 blue lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2 ">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p v-if="AVG_NOW21.length > 0">AVG : {{$store.getters.convertToCurrencyUs (AVG_NOW21[0].total_panjang) }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                            <v-card class="rounded-l mr-5 deep-orange lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    
                                    <p v-if="AVG_NOW22.length > 0">AVG : {{$store.getters.convertToCurrencyUs (AVG_NOW22[0].total_panjang) }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                        <v-card class="rounded-l mr-5 pink lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p v-if="AVG_NOW23.length > 0">AVG : {{$store.getters.convertToCurrencyUs (AVG_NOW23[0].total_panjang) }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
                    <h5 style="font-weight: 900;">Slitting </h5>
                    <h6 class="cyan-text text-darken-3 m-0">Slitting Summary (Year to Year)</h6>
                    <div id="container1" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
            <!-- <v-col cols="12" class="mt-3">
               <v-card class="pa-md-5">
                <v-col cols="12" xs="12" sm="12" md="12" class="mb-3">
                    <div class="d-flex">
                            <v-card class="rounded-l mr-5 blue-grey lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p v-if="AVG2_NOW21.length > 0">AVG : {{$store.getters.convertToCurrencyUs (AVG2_NOW21[0].total_panjang) }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                            <v-card class="rounded-l mr-5 light-green lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p v-if="AVG2_NOW22.length > 0">AVG : {{$store.getters.convertToCurrencyUs (AVG2_NOW22[0].total_panjang) }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                        <v-card class="rounded-l mr-5 orange lighten-0" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p v-if="AVG2_NOW23.length > 0">AVG : {{$store.getters.convertToCurrencyUs (AVG2_NOW23[0].total_panjang) }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col> 
                    <h5 style="font-weight: 900;">SL 2</h5>
                    <h6 class="cyan-text text-darken-3 m-0">Slitting Summary (Year to Year)</h6>
                    <div id="container2" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col> -->
            <!-- <v-col cols="12" class="mt-3">
               <v-card class="pa-md-5">
                <v-col cols="12" xs="12" sm="12" md="12" class="mb-3">
                    <div class="d-flex">
                            <v-card class="rounded-l mr-5 blue-grey lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p v-if="cgl_avg_sub2.length > 0">AVG : {{$store.getters.convertToCurrencyUs (cgl_avg_sub2[0].total_panjang) }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                            <v-card class="rounded-l mr-5 light-green lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p v-if="cgl_avg_sub.length > 0">AVG : {{$store.getters.convertToCurrencyUs (cgl_avg_sub[0].total_panjang) }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                        <v-card class="rounded-l mr-5 orange lighten-0" outlined max-width="80" min-width="160">
                            <v-card-text class="pl-4 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p v-if="cgl_avg_now.length > 0">AVG : {{$store.getters.convertToCurrencyUs (cgl_avg_now[0].total_panjang) }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
                    <h5 style="font-weight: 900;">SL 1 + SL 2</h5>
                    <h6 class="cyan-text text-darken-3 m-0">Slitting Summary (Year to Year)</h6>
                    <div id="container3" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col> -->
        </v-row>
    </v-container>
</template>

<script>
export default {
    data(){
        return{
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'Production Performance',
                disabled: false,
                href: '#',
                },
                {
                text: 'Slitting Summary',
                disabled: true,
                href: '#',
                },
            ],
            tab: 'tab-1',
            speed: null,
            AVG_NOW23:[],
            AVG_NOW22:[],
            AVG_NOW21:[],
            AVG2_NOW23:[],
            AVG2_NOW22:[],
            AVG2_NOW21:[],
            cgl_avg_now:[],
            cgl_avg_sub:[],
            cgl_avg_sub2:[],
            dashboardContent: null,
            x: window.matchMedia("(max-width: 991px)"),
            months: [
                'Jan', 'Feb', 'Mar', 'Apr', 'May',
                'Jun', 'Jul', 'Aug', 'Sep',
                'Oct', 'Nov', 'Dec'
            ],
            subyear: '',
            year: '',
            subyear2:''  
        }
    },
    mounted(){
        this.getDashboard()
    },
    methods:{
        monthNumToName(monthnum) {
            return this.months[monthnum - 1] || '';
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        getChart(dt1, dt2, dt3, container){
           // console.log(dt3);
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: [
                    {
                        crosshair: {
                            enabled: true,
                            snapToDataPoint: true
                        },
                        title: "Meter (1 X 1000M)",
                        labelFormatter: this.addSymbols,
                    },
                ],
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                
                data: [
                {
                    type: "column",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: this.subyear2+ " Production",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#64B5F6",
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "column",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: this.subyear+ " Production",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#FF8A65",
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "column",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: this.year+ " Production",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#EC407A",
                    // yValueFormatString: "#,###,,,.##",
                }]
            });

            chart.options.data[1].dataPoints = dt1;
            chart.options.data[2].dataPoints = dt2;
            chart.options.data[0].dataPoints = dt3;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        async getDashboard(){
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/SlittingSummary`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                console.log(res.data)
                this.AVG_NOW23 = res.data.AVG_NOW23
                this.AVG_NOW22 = res.data.AVG_NOW22
                this.AVG_NOW21 = res.data.AVG_NOW21
                this.AVG2_NOW23 = res.data.AVG2_NOW23
                this.AVG2_NOW22 = res.data.AVG2_NOW22
                this.AVG2_NOW21 = res.data.AVG2_NOW21
                // this.cgl_avg_now = res.data.cgl_avg_now
                // this.cgl_avg_sub = res.data.cgl_avg_sub
                // this.cgl_avg_sub2 = res.data.cgl_avg_sub2
                this.dashboardContent = res.data
                this.subyear2 = res.data.subyear2
                this.subyear = res.data.subyear
                this.year = res.data.year

                if (this.dashboardContent.SLKKA_NOW23.length > 0  && this.dashboardContent.SLKKA_SUB2022.length > 0 && this.dashboardContent.SLKKA_SUB2021.length > 0) {

                    var dt1 = [];
                    var dt2 = [];
                    var dt3 = [];
                 

                    for (var i = 0; i < this.dashboardContent.SLKKA_NOW23.length; i++) {
                        
                        dt2.push({ label: this.monthNumToName(parseInt(this.dashboardContent.SLKKA_NOW23[i].bulan)), y: parseFloat(this.dashboardContent.SLKKA_NOW23[i].total_panjang)});

                    }


                    for (var i = 0; i < this.dashboardContent.SLKKA_SUB2022.length; i++) {
                        
                        dt1.push({ label: this.monthNumToName(parseInt(this.dashboardContent.SLKKA_SUB2022[i].bulan)), y: parseFloat(this.dashboardContent.SLKKA_SUB2022[i].total_panjang) });
                    }

                    for (var i = 0; i < this.dashboardContent.SLKKA_SUB2021.length; i++) {
                        
                        dt3.push({ label: this.monthNumToName(parseInt(this.dashboardContent.SLKKA_SUB2021[i].bulan)), y: parseFloat(this.dashboardContent.SLKKA_SUB2021[i].total_panjang) });
                    }


                    var container = "container1";

                    this.getChart(dt1, dt2,dt3, container);
                    this.$store.dispatch('setOverlay', false)


                }

                if (this.dashboardContent.SLKKA_NOW23.length < 1  && this.dashboardContent.SLKKA_SUB2022.length < 1 && this.dashboardContent.SLKKA_SUB2021.length < 1) {

                    var dt1 = [];
                    var dt2 = [];
                    var dt3 = [];
                    dt1.push({ y: 0 });
                    dt2.push({ y: 0 });
                    dt3.push({ y: 0 });
                    var container = "container1";
                    this.getChart(dt1, dt2, dt3, container);

                }

                // if (this.dashboardContent.SLKKA2_NOW23.length > 0  && this.dashboardContent.SLKKA2_SUB2022.length > 0 && this.dashboardContent.SLKKA2_SUB2021.length > 0) {

                //     var dt1 = [];
                //     var dt2 = [];
                //     var dt3 = [];
    
                //     for (var i = 0; i < this.dashboardContent.SLKKA2_NOW23.length; i++) {
                                
                //         dt2.push({ label: this.monthNumToName(parseInt(this.dashboardContent.SLKKA2_NOW23[i].bulan)), y: parseFloat(this.dashboardContent.SLKKA2_NOW23[i].total_panjang) });
                     
                //     }


                //     for (var i = 0; i < this.dashboardContent.SLKKA2_SUB2022.length; i++) {
                        
                //         dt1.push({ label: this.monthNumToName(parseInt(this.dashboardContent.SLKKA2_SUB2022[i].bulan)), y: parseFloat(this.dashboardContent.SLKKA2_SUB2022[i].total_panjang) });
                //     }

                //     for (var i = 0; i < this.dashboardContent.SLKKA2_SUB2021.length; i++) {
                        
                //         dt3.push({ label: this.monthNumToName(parseInt(this.dashboardContent.SLKKA2_SUB2021[i].bulan)), y: parseFloat(this.dashboardContent.SLKKA2_SUB2021[i].total_panjang) });
                //     }

                //     var container = "container2";

                //     this.getChart(dt1, dt2,dt3, container);


                // }

                // if (this.dashboardContent.SLKKA2_NOW23.length < 1  && this.dashboardContent.SLKKA2_SUB2022.length < 1  && this.dashboardContent.SLKKA2_SUB2021.length < 1) {

                //     var dt1 = [];
                //     var dt2 = [];
                //     var dt3 = [];
                //     dt1.push({ y: 0 });
                //     dt2.push({ y: 0 });
                //     dt3.push({ y: 0 });
                //     var container = "container2";
                //     this.getChart(dt1, dt2, dt3, container);

                // }

                

                // if (this.dashboardContent.SLKKA_NOW23.length > 0  && this.dashboardContent.SLKKA_SUB2022.length > 0 && this.dashboardContent.SLKKA_SUB2021.length > 0 && this.dashboardContent.SLKKA2_NOW23.length > 0  && this.dashboardContent.SLKKA2_SUB2022.length > 0 && this.dashboardContent.SLKKA2_SUB2021.length > 0) {

                //     var dt1 = [];
                //     var dt2 = [];
                //     var dt3 = [];

                //     for (var i = 0; i < this.dashboardContent.SLKKA_NOW23.length; i++) {

                //         for (var i = 0; i < this.dashboardContent.SLKKA2_NOW23.length; i++) {

                //             if (this.monthNumToName(parseInt(this.dashboardContent.SLKKA_NOW23[i].bulan)) == this.monthNumToName(parseInt(this.dashboardContent.SLKKA2_NOW23[i].bulan))) {

                //                 dt2.push({ label: this.monthNumToName(parseInt(this.dashboardContent.SLKKA_NOW23[i].bulan)), y: parseFloat(this.dashboardContent.SLKKA_NOW23[i].total_panjang)+parseFloat(this.dashboardContent.SLKKA2_NOW23[i].total_panjang)});
                //             }

                //         }
                    
                //     }


                //     for (var i = 0; i < this.dashboardContent.SLKKA_NOW23.length; i++) {

                //         for (var i = 0; i < this.dashboardContent.SLKKA2_NOW23.length; i++) { 

                //             if (this.monthNumToName(parseInt(this.dashboardContent.SLKKA_NOW23[i].bulan)) == this.monthNumToName(parseInt(this.dashboardContent.SLKKA2_NOW23[i].bulan))) {
                               
                //                 dt1.push({ label: this.monthNumToName(parseInt(this.dashboardContent.SLKKA_NOW23[i].bulan)), y: parseFloat(this.dashboardContent.SLKKA_NOW23[i].total_panjang)+parseFloat(this.dashboardContent.SLKKA2_NOW23[i].total_panjang) });
                                
                //             }


                //         }
                        
                //     }

                //     for (var i = 0; i < this.dashboardContent.SLKKA_SUB2021.length; i++) 
                //     {
                        
                //         for (var i = 0; i < this.dashboardContent.SLKKA2_SUB2021.length; i++) { 

                //             if (this.monthNumToName(parseInt(this.dashboardContent.SLKKA_SUB2021[i].bulan)) == this.monthNumToName(parseInt(this.dashboardContent.SLKKA2_SUB2021[i].bulan))) {

                //                 dt3.push({ label: this.monthNumToName(parseInt(this.dashboardContent.SLKKA_SUB2021[i].bulan)), y: parseFloat(this.dashboardContent.SLKKA_SUB2021[i].total_panjang)+parseFloat(this.dashboardContent.SLKKA2_SUB2021[i].total_panjang) });
                                
                //             }


                //         }

                //     }
                    

                //     var container = "container3";

                //     this.getChart(dt1, dt2,dt3, container);


                // }

               

            })
        },   
        
    }

}
</script>

<style>

</style>